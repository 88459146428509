.thankyou-title {
  font-style: bold;
  font-weight: bold;
  font-size: 1.75rem;
  text-align: center;
  overflow: wrap;
  word-wrap: normal;
  color: #525a60;
}

.thankyou-text {
  color: #525a60;
}
