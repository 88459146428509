.transition-container {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.active {
  display: inline;
}

.disabled {
  display: none !important;
}
